
import { ref, defineComponent, onMounted, computed, watch } from "vue";
import { Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import moment from "moment";

export default defineComponent({
  name: "Ride",
  components: {
    Form,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
    });
    
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const loading = ref(true);
    return {
      loading,
      currentUser
    };
  },
});
